/* width */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: linear-gradient(#33d474, #2ccf6d);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #33d474;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2ccf6d;
}

.bg-gradient {
  height: 100vh;
}