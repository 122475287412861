.btn-with-input {
  border-start-start-radius: 0 !important;
  border-end-start-radius: 0 !important;
}

.ant-input {
  border-color: black !important;
}

.input-custom {
  font-family: 'K2D';
}

.input-custom:active, 
.input-custom:hover, 
.input-custom:focus {
  border-color: black !important;
}

.input-custom-id .ant-input-group-addon {
  background-color: white;
  border: 1px solid black;
  font-weight: bold;
}

.input-custom-id .ant-input, .input-custom-id .ant-input-group-addon {
  padding-top: 4px;
  padding-bottom: 4px;
  height: 43px;
}

.input-custom-id .ant-input:active, 
.input-custom-id .ant-input:hover, 
.input-custom-id .ant-input:focus {
  border: 1px solid black !important;
}

.mobile-mt-3 {
  margin-top: 9rem;
}

.btn-success {
  background-color: black !important;
  border-color: black !important;
}

.btn-success:hover {
  background-color: black !important;
  border-color: black !important;
}

@media (max-width: 768px) {
  .mobile-mt-3 {
    margin-top: 3em !important;
  }
}

@media (max-width: 425px) {
  .mobile-mt-3 {
    margin-top: 0em !important;
  }
}