body, html {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.loader {
  margin: 0 auto;
  width: 60px;
  height: 50px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
}
.loader > div {
  height: 100%;
  width: 8px;
  display: inline-block;
  float: left;
  margin-left: 2px;
  -webkit-animation: delay 0.8s infinite ease-in-out;
  animation: delay 0.8s infinite ease-in-out;
}
.loader .bar1 {
  background-color: #2ccf6d;
}
.loader .bar2 {
  background-color: #2ccf6d;
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.loader .bar3 {
  background-color: #2ccf6d;
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.loader .bar4 {
  background-color: #2ccf6d;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.loader .bar5 {
  background-color: #2ccf6d;
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.loader .bar6 {
  background-color: #2ccf6d;
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.loader-text {
  font-family: 'K2D';
  margin: 0 auto;
  width: 60px;
  height: 50px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 57%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
}

@-webkit-keyframes delay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.05);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes delay {
  0%, 40%, 100% {
    transform: scaleY(0.05);
    -webkit-transform: scaleY(0.05);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}